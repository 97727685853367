<template>
  <div class="listshow temp_common">
    <div class="base_message_title" style="margin-bottom: 20px">
      {{ current_menu.name }}
      <!--
      <div class="modify_menubtn" style="">
        <i class="icon-setting2"></i> <span>高级设置</span>
      </div>
      -->
      <el-popover placement="bottom" width="150" trigger="hover">
        <a
          class="temp_a"
          @click="class_edit_function('create_classTable', { menu: true })"
        >
          <i class="iconfont iconbianjishuru"></i
          ><span class="temp_span">编辑分类</span>
        </a>
        <el-button slot="reference">
          <i class="iconfont iconshezhi"></i
          ><span class="temp_span" style="color: #fff"
            >高级设置</span
          ></el-button
        >
      </el-popover>
    </div>
    <div class="order_filter_wrap">
      <!--
      <el-button size="small" @click.stop="image_upload('单图上传')"
        >＋单图上传</el-button
      >
      -->
      <el-button
        type="primary"
        size="medium"
        @click.stop="image_upload('批量上传')"
        >＋批量上传</el-button
      >
      <div
        class="verticalAlign fenlei_choose"
        style="float: right; margin-top: -5px"
      >
        <span
          class="
            text_margin_btn_right
            white_color
            text_margin_btn_left
            verticalAlign
          "
          >分类</span
        >
        <el-cascader
          :key="class_edit.isResouceShow"
          v-model="class_edit.el_cascader_value"
          :options="class_edit.options"
          @change="el_cascader_change"
        ></el-cascader>
      </div>
    </div>
    <div class="temp_request_area" v-loading="el_loading">
      <template v-if="!el_loading">
        <el-empty
          description="暂无内容"
          :image-size="120"
          v-if="request_data.length == 0"
        ></el-empty>
        <div
          style="
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            overflow: hidden;
            padding: 18px 0px;
          "
          v-for="(item, index) in request_data"
          v-bind:key="index"
        >
          <div style="float: left; font-size: 0px">
            <el-image
              style="width: 90px; height: 90px"
              :src="item.graphic_introduction_cover.thumbnail_image_url"
              fit="contain"
            ></el-image>
            <div
              style="
                margin-left: 18px;
                display: inline-block;
                vertical-align: top;
              "
            >
              <div
                style="
                  font-size: 14px;
                  color: rgb(51, 51, 51);
                  margin-top: 8px;
                  max-width: 400px;
                  over-flow: hidden;
                "
              >
                {{ item.graphic_introduction_cover.name }}
              </div>
              <!-- 分类-->
              <div
                style="
                  font-size: 12px;
                  color: rgb(170, 170, 170);
                  margin-top: 4px;
                "
              >
                <i
                  class="el-icon-paperclip"
                  style="margin-right: 3px; font-size: 13px"
                ></i
                >{{ match_subject(item.subject) }}
              </div>
              <div
                style="
                  color: rgb(153, 153, 153);
                  font-size: 12px;
                  margin-top: 27px;
                  line-height: 1;
                "
              >
                <span>{{ transfer_time(item.creation_time) }}</span>
              </div>
            </div>
          </div>
          <div style="float: right; margin-top: 8px">
            <div
              style="
                color: rgb(102, 102, 102);
                display: inline-block;
                font-size: 12px;
                margin: 0px 10px;
                cursor: pointer;
              "
            >
              <!--
              <i class="el-icon-edit-outline"></i>
              <span style="margin-left: 5px">编辑</span>
              -->
            </div>
            <div
              style="
                color: rgb(102, 102, 102);
                display: inline-block;
                font-size: 12px;
                margin: 0px 10px;
                cursor: pointer;
              "
              @click="delete_iamge(index)"
            >
              <i class="el-icon-delete"></i>
              <span style="margin-left: 5px">删除</span>
            </div>
          </div>
        </div>
        <div style="margin-top: 60px">
          <el-pagination
            background
            :total="el_pagination.total"
            layout="total, prev, pager, next"
            :page-size="el_pagination.page_size"
            @current-change="el_current_change"
          >
          </el-pagination>
        </div>
      </template>
    </div>

    <!--分类编辑弹出框-->
    <template>
      <el-dialog
        title="编辑分类"
        center
        :visible.sync="class_edit.dialogTableVisible"
        width="750px"
        :close-on-click-modal="false"
      >
        <template v-if="class_edit.tableData.length != 0">
          <el-table
            :data="class_edit.tableData"
            style="width: 100%"
            row-key="value"
            :default-expand-all="false"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column label="一级类名" width="320">
              <template slot-scope="scope">
                <span style="margin-left: 10px"
                  >({{ scope.row.layer }}) {{ scope.row.label }}</span
                >
                <i
                  v-if="scope.row.layer == 1 && scope.row.src.length != 0"
                  class="el-icon-picture-outline"
                  style="margin-left: 3px; color: #66b1ff"
                  title="已添加封面"
                ></i>
                <i
                  v-if="scope.row.layer == 1 && scope.row.content.length != 0"
                  class="el-icon-document-add"
                  style="margin-left: 3px; color: #66b1ff"
                  title="已添加文字"
                ></i>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="
                    class_edit_function('rename_sub_class', { row: scope.row })
                  "
                  >重命名</el-button
                >
                <el-button
                  size="mini"
                  @click="
                    class_edit_function('add_sub_class', {
                      row: scope.row,
                      title: '添加子类名',
                    })
                  "
                  v-if="scope.row.layer < 3"
                  >添加子类</el-button
                >
                <!--三级类目专用-->
                <el-button
                  size="mini"
                  @click="
                  article_edit_dialog_control(scope.row)
                  "
                  v-if="scope.row.layer == 3"
                  >图文设置</el-button
                >
                <el-popconfirm
                  v-if="scope.row.layer == 3"
                  title="确定删除该类别吗？"
                  @confirm="
                    class_edit_function('delete_sub_class', {
                      row: scope.row,
                    })
                  "
                >
                  <el-button
                    slot="reference"
                    size="mini"
                    type="danger"
                    style="margin-left: 10px"
                    :loading="class_edit.delete_row_value == scope.row.value"
                    >删除</el-button
                  >
                </el-popconfirm>
                <!--一、二级类目专用-->
                <el-dropdown
                  trigger="click"
                  size="small"
                  @command="more_operate"
                  v-if="scope.row.layer <= 2"
                >
                  <el-button
                    type="primary"
                    size="mini"
                    style="margin-left: 10px"
                  >
                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      icon="el-icon-picture-outline"
                      :command="{ item: scope.row, command: '图文设置' }"
                      >图文设置</el-dropdown-item
                    >
                    <el-dropdown-item
                      icon="el-icon-sort"
                      :command="{ item: scope.row, command: '排序' }"
                      :disabled="
                        !(
                          typeof scope.row.children != 'undefined' &&
                          scope.row.children.length > 1
                        )
                      "
                      >排序</el-dropdown-item
                    >
                    <el-dropdown-item
                      style="color: red"
                      icon="el-icon-delete"
                      :command="{ item: scope.row, command: '删除' }"
                      >删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template v-else>
          <el-empty description="空空如也~" :image-size="120"></el-empty>
        </template>
        <div slot="footer" class="dialog-footer">
          <el-button
            :disabled="class_edit.tableData.length <= 1"
            style="margin-right: 10px"
            size="small"
            @click="sort_click()"
            icon="el-icon-sort"
            >排序</el-button
          >
          <el-button
            style="margin-right: 10px"
            size="small"
            @click="
              class_edit_function('add_sub_class', {
                row: {},
                title: '添加一级类名',
              })
            "
            >添加一级类名</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click.stop="class_edit_function('save_and_quit', { menu: true })"
            >保存退出</el-button
          >
        </div>
      </el-dialog>
      <el-dialog
        :title="class_edit.dialog_title"
        center
        :visible.sync="class_edit.dialogFormVisible"
        width="500px"
        :close-on-click-modal="false"
      >
        <div style="margin: 0 30px">
          <el-input
            type="text"
            v-model="class_edit.input_value"
            placeholder="请输入您的自定义类名"
          >
            <span slot="suffix" class="slot_span"
              ><span
                :style="{
                  color: class_edit.count_input_word > 12 ? '#F56C6C' : '',
                }"
                >{{ class_edit.count_input_word }}</span
              >/12</span
            >
          </el-input>
          <ul class="explain_list">
            <li>分类最大层数为3层</li>
            <li>每层最多可添加100个自定义分类</li>
            <li>该分类将显示在您的网站、小程序中</li>
            <li>分类名称最大支持6个汉字或12个英文</li>
          </ul>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button
            size="small"
            @click.stop="class_edit.dialogFormVisible = false"
            style="margin-right: 30px"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click.stop="class_edit_function('confirm_input_change')"
            >确定</el-button
          >
        </div>
      </el-dialog>
    </template>

    <!--分类封面及文字编辑-->
    <el-dialog
      :visible.sync="article_eidt.dialogFormVisible"
      width="300px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      class="fenlei_cover_set"
    >
      <div slot="title"></div>
      <div
        class="formal_article_edit_img"
        :style="{
          backgroundImage: 'url(' + article_eidt.temp.src + ')',
        }"
      >
        <div
          style="
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            color: white;
            background-color: rgba(0, 0, 0, 0.3);
            cursor: pointer;
            font-size: 12px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            padding: 5px;
            text-align: center;
          "
          @click.stop="submitSingleUpload()"
        >
          <i class="el-icon-circle-plus-outline" style="margin-right: 3px"></i
          >点击上传类别封面
          <div style="width: 0px; height: 0px; opacity: 0; overflow: hidden">
            <input
              ref="upload_single"
              @change="file_single_change($event)"
              name="upload"
              type="file"
              accept="image/*"
            />
          </div>
        </div>
      </div>
      <div
        v-if="article_eidt.temp.img_describe"
        style="
          text-align: center;
          font-size: 12px;
          color: #8b8383;
          padding: 5px;
        "
      >
        {{ article_eidt.temp.img_describe }}
      </div>
      <div class="formal_article_layout">
        <a
          :style="{
            color: article_eidt.temp.layout == 'left' ? '#409EFF' : '',
          }"
          @click.stop="article_eidt.temp.layout = 'left'"
        >
          <i class="iconfont iconjuzuo" style="margin-right: 5px"></i>
        </a>
        <a
          :style="{
            color: article_eidt.temp.layout == 'center' ? '#409EFF' : '',
          }"
          @click.stop="article_eidt.temp.layout = 'center'"
        >
          <i class="iconfont iconjuzhong" style="margin-right: 5px"></i>
        </a>
        <el-tooltip
          class="item"
          content="文字两端对齐"
          placement="top-start"
          effect="light"
        >
          <a
            :style="{
              color: article_eidt.temp.layout == 'justify' ? '#409EFF' : '',
            }"
            @click.stop="article_eidt.temp.layout = 'justify'"
          >
            <i class="iconfont iconhengpai"></i>
          </a>
        </el-tooltip>

        <a style="float: right" @click.stop="article_eidt.temp.content = ''">
          <i class="el-icon-delete" style="font-size: 18px"></i>
        </a>
      </div>
      <textarea
        placeholder="请输入类别介绍"
        class="formal_article_textarea"
        v-model="article_eidt.temp.content"
        ref="article_edit_textarea"
        :style="{
          textAlign: article_eidt.temp.layout,
          height: article_eidt.temp.height,
        }"
      ></textarea>
      <div style="display: flex; justify-content: center">
        <el-button
          size="medium"
          style="width: 49%"
          @click.stop="article_edit_save()"
          >保存</el-button
        >
        <div style="flex: 1"></div>
        <el-button
          size="medium"
          style="width: 49%"
          @click.stop="close_article_edit_dialog()"
          >取消</el-button
        >
      </div>
    </el-dialog>

    <!--上传进度显示-->
    <el-dialog
      :visible.sync="dialogFormVisible"
      width="900px"
      :close-on-click-modal="false"
    >
      <div slot="title">
        <div class="upload_title">{{ upload_message() }}</div>
        <el-popover placement="bottom" width="390" v-model="problem_visible">
          <div class="problem_content">
            <p style="font-size: 14px; margin-bottom: 10px">
              <i
                class="el-icon-info"
                style="color: #e3912b; font-size: 20px"
              ></i>
              上传遇到问题?
            </p>
            <p style="margin-bottom: 15px">请检查以下情况后，刷新页面重试：</p>
            <p style="color: #8d8a8a">
              <span style="margin-right: 5px"
                ><i class="el-icon-check"></i></span
              >检查网络环境是否稳定
            </p>
            <p style="color: #8d8a8a">
              <span style="margin-right: 5px"
                ><i class="el-icon-check"></i></span
              >检查VPN，并确定关闭
            </p>
            <p style="color: #8d8a8a">
              <span style="margin-right: 5px"
                ><i class="el-icon-check"></i></span
              >请尝试在电脑-网络中，把dns改成223.5.5.5 或者 223.6.6.6 后重试
            </p>
            <div style="text-align: right; margin: 0">
              <button
                @click="problem_visible = false"
                type="button"
                class="ant-button blue-button"
                style="font-size: 12px; margin-top: 20px"
              >
                <span>知道了</span>
              </button>
            </div>
          </div>

          <div slot="reference" class="problem">
            <i class="el-icon-question" style="color: #e3912b"></i>
            帮助
          </div>
        </el-popover>
      </div>
      <div class="upload_progress_file">
        <ul>
          <li
            v-for="(item, index) in temp_array"
            v-bind:key="index"
            class="upload_progress_file_item"
            :class="{
              upload_progress_file_animate: index == upload_active_index,
            }"
          >
            <span class="upload_progress_item_filename" :title="item.name">{{
              item.name
            }}</span>
            <span
              class="upload_progress_item_filesize"
              :title="upload_size(index)"
              >{{ upload_size(index) }}</span
            >
            <span
              class="upload_progress_item_status"
              :style="{ color: item.result == 'fail' ? '#f44336' : '' }"
              :title="upload_status(index)"
            >
              <i
                class="el-icon-circle-check"
                style="color: #5bda92"
                v-if="item.result == 'ok'"
              ></i
              >{{ upload_status(index) }}</span
            >
          </li>
        </ul>
      </div>
    </el-dialog>

    <!--排序-->
    <el-dialog
      class="sort"
      title="排序"
      :visible.sync="sort.dialogFormVisible"
      width="1100px"
      :close-on-click-modal="false"
      :before-close="sort_confirm_close"
    >
      <div
        style="
          min-height: 390px;
          cursor: move;
          padding-top: 20px;
          overflow: hidden;
        "
      >
        <!--使用draggable组件-->
        <draggable
          v-model="sort.temp_array"
          chosenClass="draggable_chosen"
          forceFallback="true"
          animation="200"
        >
          <transition-group>
            <div
              v-for="item in sort.temp_array"
              v-bind:key="item.value"
              style="
                width: 100px;
                height: 30px;
                display: inline-block;
                margin-right: 5px;
              "
            >
              <div
                style="
                  height: 100%;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  word-break: break-all;
                  background: #409eff;
                  color: #fff;
                  font-size: 12px;
                "
              >
                {{ item.label }}
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click.stop="sort_click()">取消</el-button>
        <el-button type="primary" size="small" @click.stop="save_sort()"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import { WOW } from 'wowjs'
import plugins from '../assets/js/plugin';
import class_edit from '../assets/js/class_edit';
import calcTextareaHeight from '../assets/js/calcTextareaHeight';
//导入draggable组件
import draggable from 'vuedraggable';
export default {
  name: "Show_1",
  // 需要在子组件props中把父组件传递的属性先定义一遍，才能使用(最好不用大写)，props里面的数据都是父中传递的
  // 子组件data是子组件私有的数据，可读可写.
  // props中的数据只能读 
  // 参考https://blog.csdn.net/watson_pillow/article/details/109600259
  props: {
    current_menu: Object,
    current_menu_index: Number
  },
  data: function () {
    return {
      bg_src: 'https://miya-1253902085.cos.ap-guangzhou.myqcloud.com/bms/formal_edit/bg.png', //背景图地址
      //请求数据
      request_data: [],
      el_loading: true,  //空内容图标控制器

      //父组件传参
      menu_index: this.current_menu_index,

      // 排序
      sort: {
        dialogFormVisible: false,
        temp_array: [],// 排序临时数组
        row: null,
        index: 0
      },

      // batch_upload参数
      dialogFormVisible: false,
      temp_array: [],
      upload_array: [],
      upload_result: {   // 数量统计
        flag: 0,
        success: 0
      },
      upload_active_index: -1,
      problem_visible: false,
      is_save: false, //当销毁组件时，如果此时未点击保存，则删除临时图片

      //分类封面及文字编辑
      article_eidt: {
        dialogFormVisible: false,
        row_value: '',
        temp: {
          src: '',
          size: 0,
          from: '',
          layout: 'left',
          content: '',
          height: '78px',
        },
      },

      //分类编辑控制参数
      class_edit: {
        dialogTableVisible: false,
        el_cascader_value: ['全部'],
        options: [{           // 含首元素{value: '全部',label: '全部'}
          value: '全部',
          label: '全部'
        }],
        tableData: [],   //用于分类编辑，不含首元素{value: '全部',label: '全部'}
        input_value: '',
        count_input_word: 0,
        class_row: {},
        dialog_title: '标题',
        dialogFormVisible: false,
        // 当多个菜单项共用一个模板时，如果各自的el-cascader联级目录层数不一样，那么在选定层数较深的一方后，再切换菜单到层数较少的一方，
        // 会导致控制台报错，原因是两者共用一个模板导致没有重新渲染页面，但是绑定的数组数据却改变了(可以看到，el数组
        //数据变化后并不会刷新页面)，从而报错，解决方法也就是需要重新刷新
        // 页面，这样通过绑定一个变量，改变其值来刷新页面。参见：https://blog.csdn.net/qq_37253540/article/details/100730223
        isResouceShow: 0,
        delete_row_value: '', // 删除状态
      },

      // 分页参数
      el_pagination: {
        total: 0,
        page_size: 10,
        current_page: 1
      },

      // delete操作，用于服务器判断是否进行数量统计
      delete_op: false

    }
  },
  components: {
    draggable
  },
  // 相当于onlaunch()
  // vue实例的数据对象data在钩子函数created()执行的时候已经有了
  created () {
    this.initialize_data()
  },
  // 相当于onshow()
  mounted () {
    // 参考 https://www.jianshu.com/p/eff637c7ffc9
    var wow = new WOW({
      live: false
    })
    wow.init()
    this.$nextTick(() => {
      // es6语法，拼接数组 https://blog.csdn.net/cristina_song/article/details/82805444
      // 在最初服务器端设置menu的时候，分类菜单的options被置为[],所以是一定存在的
      console.log('this.current_menu:', this.current_menu)
      this.class_edit.options.push(...this.current_menu.options)
    })
  },
  watch: {
    'article_eidt.temp.content' () {  // 对象具体属性的watch可以直接用引号把属性括起来，就可以实现对象中特定属性的监听事件：
      let that = this
      setTimeout(() => {
        if (that.article_eidt.temp.content.length > 0) {
          that.article_eidt.temp.height = calcTextareaHeight(that.$refs.article_edit_textarea).height
          console.log('that.article_eidt.temp.height :', that.article_eidt.temp.height)
        }
      }, 60)
    },
    current_menu_index: { //深度监听，可监听到对象、数组的变化
      //监听current_menu_index，，由于此属性绑定的是父组件的变量，所以实际上监听的是父组件变量的变化
      // 由于部分菜单共用模板，在这些模板之间切换不会导致数据重新渲染，这里通过监听index的改变来刷新数据
      //当连续点击不同菜单选项(这些菜单共用相同组件)时，页面不会被重新加载,需要监听下标变化从而刷新数据
      handler () {
        this.class_edit.options = [{
          value: '全部',
          label: '全部'
        }]
        this.class_edit.el_cascader_value = ['全部']
        this.class_edit.options.push(...this.current_menu.options)
        this.menu_index = this.current_menu_index
        this.class_edit.isResouceShow = Math.random()
        this.el_pagination.total = 0
        this.el_pagination.current_page = 1
        this.el_loading = true
        this.initialize_data()
      },
      deep: true
    },
    //普通的watch监听
    'class_edit.input_value' (newVal, oldVal) {  // 对象具体属性的watch可以直接用引号把属性括起来，就可以实现对象中特定属性的监听事件：
      console.log('input_value:', newVal, oldVal)
      this.class_edit.count_input_word = newVal.length
      //中文、中文标点、全角字符按2长度，英文、英文符号、数字按1长度计算
      let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
      let mat = newVal.match(cnReg);
      if (mat) {
        this.class_edit.count_input_word = mat.length * 2 + (newVal.length - mat.length)
      } else {
        this.class_edit.count_input_word = newVal.length
      }
    }

  },
  computed: {
    // 匹配分类的名称
    match_subject () {
      return function (id) {
        // this.print('id:', id)
        // this.print('this.class_edit.options:', this.class_edit.options)
        for (let i = 0; i < this.class_edit.options.length; i++) {
          if (id == this.class_edit.options[i].value) {
            return this.class_edit.options[i].label
          }
        }
      }
    },
    transfer_time () {
      return function (creation_time) {
        // console.log('time:', creation_time)
        // getFullYear() 方法只能用于new Date()格式的时间。所以需要先做下面的转化，否则报错：getFullYear is not a function
        var date = new Date(creation_time)
        let time = plugins.formatTime_ymd(date)
        return time
      }
    },
    upload_message () {
      return function () {
        let flag = 0
        for (let i = 0; i < this.temp_array.length; i++) {
          if (this.temp_array[i].result.length != 0) {
            flag++
          }
        }
        if (flag != this.temp_array.length) {
          return '正在上传\xa0\xa0' + flag + '/' + this.temp_array.length
        }
        else {
          return '上传完成，' + '成功' + this.upload_result.success + '张，失败' + this.upload_result.fail + '张'
        }
      }
    },
    upload_size: function () {
      // 值得注意的是，如parameter 放在了 要计算的属性后面，取到的是vm实例。
      return function (index) {
        if (this.temp_array[index].size < 1) {
          return ((this.temp_array[index].size * 1000).toFixed(1)) + 'KB'
        }
        else {
          return (this.temp_array[index].size.toFixed(1)) + 'MB'
        }
      }
    },
    upload_status: function () {

      return function (index) {
        if (this.temp_array[index].result == 'ok') {
          return '上传成功'
        }
        else {
          if (this.temp_array[index].result == 'fail') {
            return this.temp_array[index].reason
          }
          else {
            if (this.temp_array[index].percentage == 0) {
              return '排队中'
            }
            else {
              if (this.temp_array[index].percentage == -2) {
                return '正在压缩'
              }
              else {
                return (this.temp_array[index].percentage - 1) + '%'
              }
            }
          }
        }
      }

    }
  },
  methods: {
    // 确认关闭排序
    sort_confirm_close (done) {
      let that = this
      this.$confirm('是否保存排序？', '提示', {
        confirmButtonText: '保存',
        cancelButtonText: '不保存',
        showCancelButton: true,
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        that.save_sort()
        done()
      })
        .catch(() => {
          done()
        })
    },
    // 保存排序
    save_sort () {
      var that = this
      this.sort.dialogFormVisible = !this.sort.dialogFormVisible
      function traverse (op_array) {
        // 算法：只在匹配成功的情况下有返回，否则可能在没有匹配成功的情况下提前结束循环
        for (let i = 0; i < op_array.length; i++) {
          if (that.sort.row.value == op_array[i].value) {
            op_array[i].children = JSON.parse(JSON.stringify(that.sort.temp_array))
            return
          } else {
            if (op_array[i].children) {
              traverse(op_array[i].children)
            } else {
              // 见算法，这里不做处理，只要遍历数组，总归能匹配成功从而终止函数
            }
          }
        }
      }
      if (this.sort.index == 0) {
        this.class_edit.tableData = JSON.parse(JSON.stringify(this.sort.temp_array))
      }
      if (this.sort.index == 1 || this.sort.index == 2) {
        traverse(this.class_edit.tableData)
      }
    },
    // 排序开关
    sort_click (index = 0, row = null) {
      console.log('sort_click', row)
      this.sort.dialogFormVisible = !this.sort.dialogFormVisible
      this.sort.temp_array = []
      if (this.sort.dialogFormVisible) {
        this.sort.row = row
        this.sort.index = index
        if (index == 0) {
          // 一级类名排序
          this.sort.temp_array = JSON.parse(JSON.stringify(this.class_edit.tableData))
        }
        if (index == 1 || index == 2) {
          this.sort.temp_array = JSON.parse(JSON.stringify(row.children))
        }
      }
    },
    //触发封面input上传
    submitSingleUpload () {
      this.$refs.upload_single.click()
    },
    // 选择封面
    file_single_change: async function (e) {
      console.log('e:', e)
      var that = this
      console.log('e:', e.target.files)
      var files = e.target.files
      if (files.length === 0) {
        console.log('No file selected.')
        return
      }
      // await cover_image_processing.delete_cover_image(that)

      // 数据初始化
      that.temp_array = []
      that.upload_result.success = 0
      that.upload_result.fail = 0
      that.upload_active_index = -1
      for (let i = 0; i < files.length; i++) {
        let obj = {
          size: files[i].size / 1024 / 1024,
          name: files[i].name,
          percentage: 0,
          result: '',  //'ok' or 'fail'
          url: {},
          reason: ''
        }
        let judge_width_height = await plugins.judge_width_height(files[i])
        obj.result = judge_width_height.result
        obj.reason = judge_width_height.reason
        that.temp_array.push(obj)
      }
      that.dialogFormVisible = true
      var oss_sign = await plugins.oss_sign(that)
      if (oss_sign.result != 'ok') {
        that.$refs.upload_single.value = null
        return
      }
      for (let i = 0; i < that.temp_array.length; i++) {
        that.upload_active_index = i
        if (that.temp_array[i].result == 'fail') {
          // 前面处理过的重复文件直接不处理
          that.upload_result.fail++
        }
        else {
          let obj = await plugins.image_batch_upload_oss(that, files[i], oss_sign.data, i)
          that.temp_array[i].size = obj.size
          that.temp_array[i].result = obj.result
          that.temp_array[i].url = obj.url
          that.temp_array[i].reason = obj.reason
          // 统计成功和失败数量
          if (that.temp_array[i].result == 'ok') {
            that.upload_result.success++
            // that.upload_array.push(that.temp_array[i])
          }
          if (that.temp_array[i].result == 'fail') {
            // 统计上传失败的文件
            that.upload_result.fail++
          }
        }
      }
      // 重新置-1，消除当前处理条目的背景色
      that.upload_active_index = -1
      that.print('temp_array:', that.temp_array)
      if (that.temp_array[0].result == 'fail') {
        return             // 上传失败
      }

      that.article_eidt.temp.src = that.temp_array[0].url.url
      that.article_eidt.temp.size = that.temp_array[0].size
      that.article_eidt.temp.from = 'temp'

      that.$refs.upload_single.value = null
      // that.dialogFormVisible=false
    },
    // 保存分类图文编辑对话框
    article_edit_save () {
      this.print('article_edit_save,this.article_eidt:', this.article_eidt)
      class_edit.class_edit_function(this, 'save_classes_cover')
    },
    // 关闭分类图文编辑对话框
    close_article_edit_dialog () {
      this.article_eidt.dialogFormVisible = false
    },
    // 初始化分类图文编辑对话框
    article_edit_dialog_control (row = {}) {
      var that = this
      // this.print('row:', row)
      this.article_eidt.dialogFormVisible = true
      //赋值
      this.article_eidt.row_value = row.value
      let temp = {
        src: row.src ? row.src : this.bg_src,
        layout: row.layout,
        content: row.content,
        height: '78px',
        from: row.from,
        size: row.size
      }
      this.article_eidt.temp = temp
      this.print('temp:', this.article_eidt)
      if (temp.content.length > 0) {
        // 如果长度大于0，则需要计算高度，防止高度坍塌为1行
        setTimeout(() => {
          console.log('1')
          that.article_eidt.temp.height = calcTextareaHeight(that.$refs.article_edit_textarea).height
        }, 50)
      }
    },
    // 更多操作
    more_operate (command) {
      let that = this
      this.print('command:', command)
      if (command.command == '图文设置') {
        this.article_edit_dialog_control(command.item)
      }
      if (command.command == '排序') {
        this.sort_click(1 * command.item.layer, command.item)
      }
      if (command.command == '删除') {
        this.$confirm('确定要删除该类别吗？', '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          showCancelButton: true,
          showClose: false,
          type: 'warning'
        }).then(() => {
          if (command.item.children && command.item.children.length != 0) {
            this.$confirm('该类别下存在子类，请先删除所有子类', '提示', {
              confirmButtonText: '知道了',
              cancelButtonText: '',
              closeOnClickModal: false,
              showCancelButton: false,
              showClose: false,
              type: 'error'
            })
            return
          }
          else {
            that.class_edit_function('delete_sub_class', {
              row: command.item
            })
          }
        })
      }
      /*
      if (command.command == '编辑') {
        this.edit_account(command.item)
      }
      if (command.command == '删除') {
        this.delete_account(command.item)
      }
      */
    },
    delete_iamge (index) {
      let that = this
      this.print('delete image:', this.request_data[index])
      this.$confirm('确定要删除该张图片吗？', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        showClose: false,
        type: 'warning'
      }).then(() => {
        let temp = that.$store.state.accept_data
        // 这里只处理了cover图片，是个简化版本，正常还需要删除组图
        let file = this.request_data[index].graphic_introduction_cover.path
        let file_temp = file.split('?')[0] //截取参数前面的文件路径
        let file_name = this.request_data[index].graphic_introduction_cover.from + '/' + file_temp.substr(file_temp.lastIndexOf('/') + 1)  //这里肯定是来自temp文件夹
        plugins.oss_delete(this, file_name)
        that.delete_op = true
        let content = {
          type: 'delete_img_temp_list_show_one',
          phone: temp.phone,
          session: that.$store.state.session,
          _id: this.request_data[index]._id,
        }
        plugins.bms_update(that, content).then(() => {
          that.initialize_data()
          that.delete_op = false
          that.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          })
        }).catch(() => {
          that.delete_op = false
        })

      }).catch(() => {

      })

    },
    el_cascader_change () {
      console.log("el_cascader_change")
      this.el_pagination.total = 0
      this.el_pagination.current_page = 1
      this.initialize_data()
    },
    // 数据初始化
    initialize_data () {
      let that = this
      let temp = that.$store.state.accept_data
      let content = {
        type: 'temp_list_show_one_page_load',
        phone: temp.phone,
        session: that.$store.state.session,
        current_page: this.el_pagination.current_page,
        page_size: this.el_pagination.page_size,
        AuthorizerAppid_id: temp.AuthorizerAppid_id,
        menu_value: this.current_menu.value,
        subject: this.class_edit.el_cascader_value[0],
        subject2: this.class_edit.el_cascader_value[1] || '',
        subject3: this.class_edit.el_cascader_value[2] || '',
        delete_op: this.delete_op
      }
      plugins.bms_download(this, content)
        .then((res) => {
          that.print('res:', res)
          this.el_loading = false
          this.request_data = []
          this.request_data = res.page_data
          if (res.count > -1) {
            this.el_pagination.total = res.count
          }
        })
        .catch((err) => {
          console.log('plugins.bms_download catch:', err)
          this.el_loading = false
        })
    },
    // 分页
    el_current_change (val) {
      console.log(`当前页: ${val}`)
      console.log('e:', val)
      this.el_pagination.current_page = val
      this.initialize_data()
    },
    // 分类编辑控制函数
    class_edit_function (type, param) {
      // this.print('this.class_edit.options:', this.class_edit.options)
      param = param || null
      console.log('param:', param)
      class_edit.class_edit_function(this, type, param)
    },
    // 父组件传递方法，使用v-on:的方式绑定（也可简写@）
    // 子组件通过$emit()出发父传递的方法
    // $emit（）方法的第一个参数为父组件绑定的名称，之后的参数为传递给函数的参数；
    // 子组件可以通过传参的方式向父组件传递数据；
    image_upload (data) {
      this.$emit('image_upload', data)
    },
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../assets/scss/formaledit.scss';
@import '../assets/scss/temp_common.scss';
.transition-box {
  margin-bottom: 10px;
  width: 200px;
  height: 100px;
  border-radius: 4px;
  background-color: #409eff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-right: 20px;
}
.fenlei_cover_set /deep/ .el-dialog__header {
  height: 0;
  padding: 0;
}
.sort /deep/ .el-dialog__header {
  font-size: 14px;
  padding-top: 20px;
  color: #333;
}
</style>
